import { TBaseResult } from "../types/general/generalType";
import { _fetch } from "../utils/fetch";

export const refreshToken = (token?: string) => {
  return (dispatch: any) => {
    _fetch(
      "/api/auth/refresh-token",
      "REFRESH_TOKEN",
      JSON.stringify({
        token,
      }),
      dispatch
    );
  };
};

export const signin = (
  email: string,
  password: string,
  isRemember: boolean
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/auth/signin",
      "SIGNIN",
      JSON.stringify({
        email,
        password,
        isRemember,
      }),
      dispatch
    );
  };
};

export const signup = (
  companyName: string,
  name: string,
  email: string,
  password: string
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/auth/signup",
      "SIGNUP",
      JSON.stringify({
        companyName,
        name,
        email,
        password,
      }),
      dispatch
    );
  };
};

export const signout = () => {
  return (dispatch: any) => {
    _fetch("/api/auth/signout", "SIGNOUT", JSON.stringify({}), dispatch);
  };
};

export const changePassword = (oldPassword: string, password: string) => {
  return (dispatch: any) => {
    _fetch(
      "/api/auth/change-password",
      "CHANGE-PASSWORD",
      JSON.stringify({
        oldPassword,
        password,
      }),
      dispatch
    );
  };
};

export const resetPassword = (
  email: string,
  callback: (result: TBaseResult) => void
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/auth/reset-password",
      "RESET-PASSWORD",
      JSON.stringify({
        email,
      }),
      dispatch,
      callback
    );
  };
};

export const onResetPassword = (
  newPassword: string,
  token: string,
  callback: (result: any) => void
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/auth/on-reset-password",
      "ON-RESET-PASSWORD",
      JSON.stringify({
        newPassword,
        token,
      }),
      dispatch,
      callback
    );
  };
};