import { useEffect, useState } from "react";
import { Row, Col } from "react-grid-system";
import {
  Card,
  Button,
  InputText,
  Flex,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

import { getUsers, addUser, updateUser } from "../../actions/adminAction";

import { useAppDispatch } from "../../reducers";

import { TbaseReducer } from "../../types/reducersType";
import { TUser } from "../../types/admin/adminType";

function AdminCompany() {
  const dispatch = useAppDispatch();
  const { companyId } = useParams();

  const [user, setUser] = useState<Partial<TUser>>({});

  const { users } = useSelector((state: TbaseReducer) => state.adminReducer);

  useEffect(() => {
    if (companyId) {
      dispatch(getUsers(parseInt(companyId)));
    }
  }, [companyId]);

  const _addUser = () => {
    if (companyId && user.userEmail && user.userName && user.userPhone) {
      dispatch(
        addUser(companyId, user.userEmail, user.userName, user.userPhone)
      );
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  const _updateUser = () => {
    if (user.userId && user.userEmail && user.userName && user.userPhone) {
      dispatch(
        updateUser(user.userId, user.userEmail, user.userName, user.userPhone)
      );
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  return (
    <Row
      style={{
        rowGap: 30,
        padding: 15,
      }}
    >
      <Col xs={12}>
        <h2 className="mt-0 mb-0">Administration - {companyId}</h2>
      </Col>
      <Col xs={12}>
        <Card title="Ajouter un utilisateur">
          <Row style={{ rowGap: 10 }}>
            <Col xs={12} md={4}>
              <InputText
                placeholder="E-mail"
                value={user.userEmail}
                onChange={(e) =>
                  setUser({ ...user, userEmail: e.target.value })
                }
              />
            </Col>
            <Col xs={12} md={4}>
              <InputText
                placeholder="Nom"
                value={user.userName}
                onChange={(e) => setUser({ ...user, userName: e.target.value })}
              />
            </Col>
            <Col xs={12} md={4}>
              <InputText
                placeholder="Téléphone"
                value={user.userPhone}
                onChange={(e) =>
                  setUser({ ...user, userPhone: e.target.value })
                }
              />
            </Col>
            <Col xs={12}>
              <Flex gap={10} justify="end">
                {" "}
                <Button
                  text="Rénitialiser"
                  outline
                  onClick={() =>
                    setUser({
                      ...{},
                      userEmail: "",
                      userName: "",
                      userPhone: "",
                    })
                  }
                />
                <Button
                  text={user.userId ? "Modifier" : "Ajouter"}
                  onClick={() => {
                    if (user.userId) {
                      _updateUser();
                    } else {
                      _addUser();
                    }
                  }}
                />
              </Flex>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={12}>
        <table className="dashboard-table">
          <thead>
            <tr>
              <th>Nom</th>
              <th>E-mail</th>
              <th>Dernière connexion</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, idx) => (
              <tr key={idx}>
                <td>{user.userName}</td>
                <td>{user.userEmail}</td>
                <td>
                  {user.userDateSignin
                    ? moment(user.userDateSignin).format("DD/MM/YYYY HH:mm:ss")
                    : null}
                </td>
                <td>
                  <Button
                    text="Modifier"
                    style={{ width: "100%" }}
                    onClick={() => setUser(user)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Col>
    </Row>
  );
}

export default AdminCompany;
