import React, { useState } from "react";

interface FormData {
  posteEmission: string;
  sousPoste: string;
  donneeRecherchee: string;
  sourceDonnee: string;
  avancementCollecte: string;
  sourceDocument: string;
  commentaires: string;
}

const Form = () => {
  const [formData, setFormData] = useState<FormData>({
    posteEmission: "",
    sousPoste: "",
    donneeRecherchee: "",
    sourceDonnee: "",
    avancementCollecte: "",
    sourceDocument: "",
    commentaires: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Poste d'émission:</label>
        <input
          type="text"
          name="posteEmission"
          value={formData.posteEmission}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Sous-poste:</label>
        <input
          type="text"
          name="sousPoste"
          value={formData.sousPoste}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Donnée recherchée:</label>
        <input
          type="text"
          name="donneeRecherchee"
          value={formData.donneeRecherchee}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Source de la donnée:</label>
        <input
          type="text"
          name="sourceDonnee"
          value={formData.sourceDonnee}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Avancement de la collecte:</label>
        <input
          type="text"
          name="avancementCollecte"
          value={formData.avancementCollecte}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Source du document:</label>
        <input
          type="text"
          name="sourceDocument"
          value={formData.sourceDocument}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Commentaires:</label>
        <textarea
          name="commentaires"
          value={formData.commentaires}
          onChange={handleChange}
        />
      </div>
      <button type="submit">Soumettre</button>
    </form>
  );
};

export default Form;
