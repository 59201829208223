import { useEffect, useState } from "react";
import { Row, Col } from "react-grid-system";
import {
  Card,
  Button,
  InputText,
  Flex,
  InputSelect,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

import { useAppDispatch } from "../../reducers";

import {
  getCompanies,
  addCompany,
  updateCompany,
} from "../../actions/adminAction";

import { TbaseReducer } from "../../types/reducersType";
import { TCompany } from "../../types/admin/adminType";

function Admin() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [company, setCompany] = useState<Partial<TCompany>>({});

  const { companies } = useSelector(
    (state: TbaseReducer) => state.adminReducer
  );

  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  const _addCompany = () => {
    if (
      company.companyName &&
      company.companyPhone &&
      company.companyEmail &&
      company.companyAddress1 &&
      company.companyAddress2 &&
      company.companyCity &&
      company.companyZipCode &&
      company.companyType &&
      company.companySiret &&
      company.companyTva &&
      company.companyCapital
    ) {
      dispatch(
        addCompany(
          company.companyName,
          company.companyPhone,
          company.companyEmail,
          company.companyAddress1,
          company.companyAddress2,
          company.companyCity,
          company.companyZipCode,
          company.companyType,
          company.companySiret,
          company.companyTva,
          company.companyCapital
        )
      );
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  const _updateCompany = () => {
    if (
      company.companyId &&
      company.companyName &&
      company.companyPhone &&
      company.companyEmail &&
      company.companyAddress1 &&
      company.companyAddress2 &&
      company.companyCity &&
      company.companyZipCode &&
      company.companyType &&
      company.companySiret &&
      company.companyTva &&
      company.companyCapital
    ) {
      dispatch(
        updateCompany(
          company.companyId,
          company.companyName,
          company.companyPhone,
          company.companyEmail,
          company.companyAddress1,
          company.companyAddress2,
          company.companyCity,
          company.companyZipCode,
          company.companyType,
          company.companySiret,
          company.companyTva,
          company.companyCapital
        )
      );
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  return (
    <Row
      style={{
        rowGap: 30,
        padding: 15,
      }}
    >
      <Col xs={12}>
        <h2 className="mt-0 mb-0">
          Administration - gestion des organisations
        </h2>
      </Col>
      <Col xs={12}>
        <Card title="Ajouter une organisation">
          <Row style={{ rowGap: 10 }}>
            <Col xs={12} md={4}>
              <InputText
                placeholder="Nom"
                value={company.companyName}
                onChange={(e) =>
                  setCompany({ ...company, companyName: e.target.value })
                }
              />
            </Col>
            <Col xs={12} md={4}>
              <InputText
                placeholder="Téléphone"
                value={company.companyPhone}
                onChange={(e) =>
                  setCompany({ ...company, companyPhone: e.target.value })
                }
              />
            </Col>
            <Col xs={12} md={4}>
              <InputText
                placeholder="E-mail"
                value={company.companyEmail}
                onChange={(e) =>
                  setCompany({ ...company, companyEmail: e.target.value })
                }
              />
            </Col>
            <Col xs={12} md={6}>
              <InputText
                placeholder="Adresse 1"
                value={company.companyAddress1}
                onChange={(e) =>
                  setCompany({ ...company, companyAddress1: e.target.value })
                }
              />
            </Col>
            <Col xs={12} md={6}>
              <InputText
                placeholder="Adresse 2"
                value={company.companyAddress2}
                onChange={(e) =>
                  setCompany({ ...company, companyAddress2: e.target.value })
                }
              />
            </Col>
            <Col xs={12} md={4}>
              <InputText
                placeholder="Code postal"
                value={company.companyZipCode}
                onChange={(e) =>
                  setCompany({ ...company, companyZipCode: e.target.value })
                }
              />
            </Col>
            <Col xs={12} md={8}>
              <InputText
                placeholder="Ville"
                value={company.companyCity}
                onChange={(e) =>
                  setCompany({ ...company, companyCity: e.target.value })
                }
              />
            </Col>
            <Col xs={12} md={4}>
              <InputText
                placeholder="SIRET"
                value={company.companySiret}
                onChange={(e) =>
                  setCompany({ ...company, companySiret: e.target.value })
                }
              />
            </Col>
            <Col xs={12} md={4}>
              <InputText
                placeholder="n° TVA"
                value={company.companyTva}
                onChange={(e) =>
                  setCompany({ ...company, companyTva: e.target.value })
                }
              />
            </Col>
            <Col xs={12} md={4}>
              <InputText
                placeholder="Capital"
                value={company.companyCapital}
                onChange={(e) =>
                  setCompany({ ...company, companyCapital: e.target.value })
                }
              />
            </Col>
            <Col xs={12} md={4}>
              <InputSelect
                placeholder="Type"
                options={[
                  { value: "Assureur", text: "Assureur" },
                  { value: "Épaviste", text: "Épaviste" },
                  { value: "Expert", text: "Expert" },
                ]}
                value={company.companyType || ""}
                onChange={(value) =>
                  setCompany({ ...company, companyType: value.toString() })
                }
              />
            </Col>
            <Col xs={12}>
              <Flex gap={10} justify="end">
                <Button
                  text="Rénitialiser"
                  outline
                  onClick={() =>
                    setCompany({
                      ...{},
                      companyName: "",
                      companyPhone: "",
                      companyEmail: "",
                      companyAddress1: "",
                      companyAddress2: "",
                      companyCity: "",
                      companyZipCode: "",
                      companyType: "",
                      companySiret: "",
                      companyTva: "",
                      companyCapital: "",
                    })
                  }
                />
                <Button
                  text={company.companyId ? "Modifier" : "Ajouter"}
                  onClick={() => {
                    if (company.companyId) {
                      _updateCompany();
                    } else {
                      _addCompany();
                    }
                  }}
                />
              </Flex>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={12}>
        <div className="overflow-x-auto">
          <table className="dashboard-table">
            <thead>
              <tr>
                <th>Nom</th>
                <th>Type</th>
                <th>Nb. utilisateurs</th>
                <th>Adresse</th>
                <th>Code postal</th>
                <th>Ville</th>
                <th>SIRET</th>
                <th>Constante PRE</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {companies.map((company, idx) => (
                <tr key={idx}>
                  <td>{company.companyName}</td>
                  <td>{company.companyType}</td>
                  <td>{company.userCount}</td>
                  <td>
                    {company.companyAddress1} {company.companyAddress2}
                  </td>
                  <td>{company.companyZipCode}</td>
                  <td>{company.companyCity}</td>
                  <td>{company.companySiret}</td>
                  <td>{company.companyReusePart[moment().format("YYYY")]}</td>
                  <td>
                    <Flex gap={10} justify="end">
                      <Button
                        text="Modifier"
                        style={{ width: "100%" }}
                        onClick={() => setCompany(company)}
                      />
                      <Button
                        text="Entrer"
                        style={{ width: "100%" }}
                        onClick={() => {
                          navigate(company.companyId.toString());
                        }}
                      />
                    </Flex>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Col>
    </Row>
  );
}

export default Admin;
