import {
  TCompanyInfo,
  TauthInitialState,
} from "../types/auth/authType";

export const initialState = {
  isAuthenticated: false,
  userId: null,
  name: "",
  email: "",
  phone: "",
  role: 0,
  companyId: "",
  companyInfo: {} as TCompanyInfo,
  isEmailExist: false,
  isSignup: false,
  isError: false,
  isSuccess: false,
} satisfies TauthInitialState;

export const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "REFRESH_TOKEN":
      return {
        ...state,
        isAuthenticated: action.success,
        userId: action.data ? action.data.userId : null,
        name: action.data ? action.data.userName : "",
        email: action.data ? action.data.userEmail : "",
        phone: action.data ? action.data.userPhone : "",
        role: action.data ? action.data.userRole : 0,
        companyId: action.data ? action.data.companyId : "",
        companyInfo: action.data ? action.data.companyInfo : {},
      };
    case "SIGNIN":
      return {
        ...state,
        isError: !action.success,
        isAuthenticated: action.success,
      };
    case "SIGNUP":
      return {
        ...state,
        isEmailExist: action.isExist,
        isSignup: action.success,
      };
    case "SIGNOUT":
      return {
        ...state,
        isAuthenticated: false,
      };
    case "CHANGE-PASSWORD":
      return {
        ...state,
        isError: !action.success,
        isSuccess: action.success,
      };
    case "RESET_IS_ERROR":
      return {
        ...state,
        isError: false,
      };

    case "UPDATE-COMPANY":
      return {
        ...state,
        companyInfo: action.data,
      };

    case "UPDATE-RECALL":
      return {
        ...state,
        recallInfo: action.data,
      };
    default:
      return state;
  }
};
