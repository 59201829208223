export const _fetch = (
  url: string,
  type: string,
  body: string,
  dispatch: any,
  callback?: any,
  toastMessage?: string
) => {
  dispatch({ type: "START_LOAD" });

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      dispatch({ type: "STOP_LOAD" });
      dispatch({ type, ...res, body: JSON.parse(body) });
      if (res.success) {
        if (toastMessage) {
        }
      } else {
        if (!url.includes("refresh-token")) {
        }
      }

      if (callback) {
        if (res.success) {
          callback(res);
        }
      }
    })
    .catch((err) => {
      dispatch({ type: "STOP_LOAD" });
      console.log(err);

      console.log("API failed");
      console.log("Une erreur est survenue");
    });
};
