import { _fetch } from "../utils/fetch";

export const getCompanies = () => {
  return (dispatch: any) => {
    _fetch(
      "/api/admin/get-companies",
      "GET_COMPANIES",
      JSON.stringify({}),
      dispatch
    );
  };
};

export const getCompany = (companyId: number) => {
  return (dispatch: any) => {
    _fetch(
      "/api/admin/get-company",
      "GET_COMPANY",
      JSON.stringify({ companyId }),
      dispatch
    );
  };
};

export const addCompany = (
  companyName: string,
  companyPhone: string,
  companyEmail: string,
  companyAddress1: string,
  companyAddress2: string,
  companyCity: string,
  companyZipCode: string,
  companyType: string,
  companySiret: string,
  companyTva: string,
  companyCapital: string
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/admin/add-company",
      "ADD_COMPANY",
      JSON.stringify({
        companyName,
        companyPhone,
        companyEmail,
        companyAddress1,
        companyAddress2,
        companyCity,
        companyZipCode,
        companyType,
        companySiret,
        companyTva,
        companyCapital,
      }),
      dispatch
    );
  };
};

export const updateCompany = (
  companyId: number,
  companyName: string,
  companyPhone: string,
  companyEmail: string,
  companyAddress1: string,
  companyAddress2: string,
  companyCity: string,
  companyZipCode: string,
  companyType: string,
  companySiret: string,
  companyTva: string,
  companyCapital: string
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/admin/update-company",
      "UPDATE_COMPANY",
      JSON.stringify({
        companyId,
        companyName,
        companyPhone,
        companyEmail,
        companyAddress1,
        companyAddress2,
        companyCity,
        companyZipCode,
        companyType,
        companySiret,
        companyTva,
        companyCapital,
      }),
      dispatch
    );
  };
};

export const getUsers = (companyId: number) => {
  return (dispatch: any) => {
    _fetch(
      "/api/admin/get-users",
      "GET_USERS",
      JSON.stringify({ companyId }),
      dispatch
    );
  };
};

export const getUser = (userId: number) => {
  return (dispatch: any) => {
    _fetch(
      "/api/admin/get-user",
      "GET_USER",
      JSON.stringify({ userId }),
      dispatch
    );
  };
};

export const addUser = (
  userCompany: string,
  userEmail: string,
  userName: string,
  userPhone: string
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/admin/add-user",
      "ADD_USER",
      JSON.stringify({ userCompany, userEmail, userName, userPhone }),
      dispatch
    );
  };
};

export const updateUser = (
  userId: number,
  userEmail: string,
  userName: string,
  userPhone: string
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/admin/update-user",
      "UPDATE_USER",
      JSON.stringify({ userId, userEmail, userName, userPhone }),
      dispatch
    );
  };
};
