import { TadminInitialState, TCompany, TUser } from "../types/admin/adminType";

export const initialState = {
  companies: [],
  users: [],
} satisfies TadminInitialState;

export const adminReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "GET_COMPANIES":
      return {
        ...state,
        companies: action.success ? action.data : [],
      };
    case "ADD_COMPANY":
      return {
        ...state,
        companies: action.success
          ? [
              ...state.companies,
              {
                companyId: action.companyId,
                ...action.body,
                userCount: 0,
              },
            ]
          : state.companies,
      };
    case "UPDATE_COMPANY":
      let companies = JSON.parse(JSON.stringify(state.companies));

      if (action.success) {
        companies = companies.map((element: TCompany) => {
          if (element.companyId === action.body.companyId) {
            return { ...action.body, userCount: element.userCount };
          } else {
            return element;
          }
        });
      }

      return {
        ...state,
        companies,
      };
    case "GET_USERS":
      return {
        ...state,
        users: action.success ? action.data : [],
      };
    case "ADD_USER":
      return {
        ...state,
        users: action.success
          ? [
              ...state.users,
              {
                userId: action.userId,
                ...action.body,
              },
            ]
          : state.users,
      };
    case "UPDATE_USER":
      let users = JSON.parse(JSON.stringify(state.users));

      if (action.success) {
        users = users.map((element: TUser) => {
          if (element.userId === action.body.userId) {
            return { ...action.body };
          } else {
            return element;
          }
        });
      }

      return {
        ...state,
        users,
      };
    default:
      return state;
  }
};
