import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import { Card } from "@sahadevia/ui-kit-modern-sahadevia";
import moment from "moment";
import { TChart } from "../../types/data/dataType";

interface VerticalLineChartProps {
  title: string;
  label: string;
  data: TChart[];
}

function VerticalLineChart({ title, data, label }: VerticalLineChartProps) {
  return (
    <Card
      title={title}
      className="h-100"
      style={{
        backgroundColor: "var(--white)",
        border: "none",
      }}
    >
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="name"
            tickFormatter={(tick) => moment(tick, "YYYY-MM").format("MM/YYYY")}
          />
          <YAxis />
          <Tooltip
            formatter={(value, name) => [value, label]}
            // labelFormatter={() => ""}
          />
          <Line type="monotone" dataKey="value" stroke="#cbd749" />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
}

export default VerticalLineChart;
