import { TloaderInitialState } from "../types/loader/loaderType";

export const initialState = {
  isLoad: 0,
} satisfies TloaderInitialState;

export const loaderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "START_LOAD":
      return {
        ...state,
        isLoad: state.isLoad + 1,
      };
    case "STOP_LOAD":
      return {
        ...state,
        isLoad: state.isLoad - 1,
      };
    default:
      return state;
  }
};
