import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Card } from "@sahadevia/ui-kit-modern-sahadevia";
import { TChart } from "../../types/data/dataType";

interface VerticalBarChartProps {
  title: string;
  label: string;
  data: TChart[];
}

function VerticalBarChart({ title, data, label }: VerticalBarChartProps) {
  return (
    <Card
      title={title}
      className="h-100"
      style={{
        backgroundColor: "var(--white)",
        border: "none",
      }}
    >
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          layout="horizontal" // Makes the bars horizontal
          data={data}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 60,
          }}
        >
          <CartesianGrid strokeDasharray="0" vertical={false} />
          <XAxis
            type="category"
            dataKey="name"
            angle={-45} // Incliner le texte à 45 degrés
            textAnchor="end" // Alignement à droite
            interval={0} // Affiche tous les labels
            tick={{ fontSize: 12 }} // Réduire la taille de la police des labels
          />
          {/* Sets the X-axis scale from 55 to 80 */}
          <YAxis type="number" />
          <Tooltip
            formatter={(value, name) => [value, label]}
            // labelFormatter={() => ""}
          />
          <Bar
            dataKey="value"
            label={label}
            barSize={30}
            fill="#cbd749"
            radius={[0, 30, 0, 30]}
          />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
}

export default VerticalBarChart;
