import { Card } from "@sahadevia/ui-kit-modern-sahadevia";
import { Row, Col } from "react-grid-system";

import CO2BarChart from "../../components/dashboard/CO2BarChart";
import VirtuousTable from "../../components/dashboard/VirtuousTable";

function Home() {
  return (
    <>
      <Row
        style={{
          rowGap: 30,
          padding: 15,
        }}
      >
        <Col xs={12}>
          <h2 className="mt-0 mb-0">Accueil</h2>
        </Col>
        <Col xs={12}>
          <Card
            title={"Émission en CO2e globale"}
            className="h-100"
            style={{
              color: "var(--primary-bg-color",
              background: "var(--secondary-bg-color)",
              border: "none",
            }}
            action={<span>Émission en CO2e globale</span>}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <h1 style={{ margin: 0, fontSize: 90 }}>
                15 <span style={{ fontSize: 60 }}>kt</span>
              </h1>
              <p style={{ margin: 0 }}>47 kg (moyenne / dossier)</p>
              <button
                style={{
                  textAlign: "right",
                  color: "var(--primary-bg-color)",
                }}
              >
                Afficher les détails
              </button>
            </div>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card
            title={"Gain moy CO2e rep pièces"}
            className="h-100"
            style={{
              backgroundColor: "var(--white)",
              border: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <h2 style={{ margin: 0, fontSize: 50 }}>
                20,72 <span style={{ fontSize: 30 }}>kg</span>
              </h2>
              <p style={{ margin: 0 }}>-0,02 kg par rapport au mois dernier</p>
            </div>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card
            title={"Gain cumul CO2e rep pièces"}
            className="h-100"
            style={{
              backgroundColor: "var(--white)",
              border: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <h2 style={{ margin: 0, fontSize: 50 }}>
                20,72 <span style={{ fontSize: 30 }}>kg</span>
              </h2>
              <p style={{ margin: 0 }}>-0,02 kg par rapport au mois dernier</p>
            </div>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card
            title={"Gain moy CO2e PRE"}
            className="h-100"
            style={{
              backgroundColor: "var(--white)",
              border: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <h2 style={{ margin: 0, fontSize: 50 }}>
                20,72 <span style={{ fontSize: 30 }}>kg</span>
              </h2>
              <p style={{ margin: 0 }}>-0,02 kg par rapport au mois dernier</p>
            </div>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card
            title={"Gain cumul CO2e PRE"}
            className="h-100"
            style={{
              backgroundColor: "var(--white)",
              border: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <h2 style={{ margin: 0, fontSize: 50 }}>
                20,72 <span style={{ fontSize: 30 }}>kg</span>
              </h2>
              <p style={{ margin: 0 }}>-0,02 kg par rapport au mois dernier</p>
            </div>
          </Card>
        </Col>
        <Col xs={12}>
          <CO2BarChart />
        </Col>
        <Col xs={12} md={12} lg={5}>
        </Col>
        <Col xs={12} md={12} lg={7}>
          <VirtuousTable />
        </Col>
      </Row>
    </>
  );
}

export default Home;
