export const getNumber = (value: number, digits = 2) => {
  const options = {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };

  return new Intl.NumberFormat("fr-FR", { ...options }).format(value);
};

export const getPercentage = (value: number, digits = 2) => {
  const options = {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };

  return new Intl.NumberFormat("fr-FR", {
    ...options,
    style: "percent",
  }).format(value / 100);
};
