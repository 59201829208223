import styles from "../styles/auth/Auth.module.css";

type AuthLayoutProps = {
  children: JSX.Element;
};

function AuthLayout({ children }: AuthLayoutProps) {
  const windowWidth = window.innerWidth;

  return (
    <>
      <div className={styles.auth}>
        {windowWidth > 950 ? <Filter /> : null}
        {children}
      </div>
    </>
  );
}

const Filter = () => {
  return <div className={styles.auth_filter_main_container} />;
};

export default AuthLayout;
