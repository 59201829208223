import React from "react";
import { Col, Row } from "react-grid-system";
import Form from "../../components/dashboard/Form";

function Prepare() {
  return (
    <Row
      style={{
        rowGap: 30,
        padding: 15,
      }}
    >
      <Col xs={12}>
        <h2 className="mt-0 mb-0">Péparer mon bilan</h2>
      </Col>
      <Col xs={12}>
        <Form />
      </Col>
    </Row>
  );
}

export default Prepare;
