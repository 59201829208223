import { _fetch } from "../utils/fetch";

export const getTable = () => {
  return (dispatch: any) => {
    _fetch("/api/data/get-table", "GET_TABLE", JSON.stringify({}), dispatch);
  };
};

export const getDashboardRecycler = () => {
  return (dispatch: any) => {
    _fetch(
      "/api/data/get-dashboard-recycler",
      "GET_DASHBOARD_RECYCLER",
      JSON.stringify({}),
      dispatch
    );
  };
};

export const countDataPartsRecycler = () => {
  return (dispatch: any) => {
    _fetch(
      "/api/data/count-data-parts-recycler",
      "COUNT_DATA_PARTS_RECYCLER",
      JSON.stringify({}),
      dispatch
    );
  };
};

export const getDataPartsRecycler = (pageLength: number, page: number) => {
  return (dispatch: any) => {
    _fetch(
      "/api/data/get-data-parts-recycler",
      "GET_DATA_PARTS_RECYCLER",
      JSON.stringify({ pageLength, page }),
      dispatch
    );
  };
};

export const countDataRecycler = () => {
  return (dispatch: any) => {
    _fetch(
      "/api/data/count-data-recycler",
      "COUNT_DATA_RECYCLER",
      JSON.stringify({}),
      dispatch
    );
  };
};

export const getDataRecycler = (pageLength: number, page: number) => {
  return (dispatch: any) => {
    _fetch(
      "/api/data/get-data-recycler",
      "GET_DATA_RECYCLER",
      JSON.stringify({ pageLength, page }),
      dispatch
    );
  };
};

export const importDataRecycler = (data: string) => {
  return (dispatch: any) => {
    _fetch(
      "/api/data/import-data-recycler",
      "IMPORT_DATA_RECYCLER",
      JSON.stringify({ data }),
      dispatch
    );
  };
};
