import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Card } from "@sahadevia/ui-kit-modern-sahadevia";

const data = [
  { name: "Renault", emissions: 68 },
  { name: "Peugeot", emissions: 65 },
  { name: "Citroen", emissions: 63 },
  { name: "Volkswagen", emissions: 70 },
  { name: "Toyota", emissions: 60 },
  { name: "Dacia", emissions: 64 },
  { name: "Mercedes", emissions: 71 },
  { name: "Ford", emissions: 66 },
  { name: "Audi", emissions: 69 },
  { name: "BMW", emissions: 75 },
  { name: "Fiat", emissions: 72 },
  { name: "Opel", emissions: 68 },
  { name: "Nissan", emissions: 62 },
  { name: "Hyundai", emissions: 65 },
  { name: "Kia", emissions: 67 },
  { name: "Suzuki", emissions: 77 },
  { name: "Mini", emissions: 85 },
  { name: "Seat", emissions: 64 },
  { name: "Divers", emissions: 61 },
  { name: "Tesla", emissions: 0 },
];

const CO2BarChart: React.FC = () => {
  return (
    <Card
      title={"Émissions de CO2e moyenne (en kg)"}
      className="h-100"
      style={{
        backgroundColor: "var(--white)",
        border: "none",
      }}
    >
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="0" vertical={false} />
          <XAxis dataKey="name" angle={-45} textAnchor="end" height={60} />
          <YAxis
            label={{
              angle: -90,
              position: "insideLeft",
            }}
          />
          <Tooltip />
          {/* <Legend /> */}
          <Bar
            dataKey="emissions"
            barSize={40}
            fill="#cbd749"
            radius={[0, 30, 0, 30]}
          />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default CO2BarChart;
