import { combineReducers } from "redux";
import { useDispatch } from "react-redux";

import { authReducer } from "./authReducer";
import { adminReducer } from "./adminReducer";
import { loaderReducer } from "./loaderReducer";
import { dataReducer } from "./dataReducer";

import { AppDispatch } from "../store";

const config = {
  authReducer,
  adminReducer,
  loaderReducer,
  dataReducer,
};

const appReducer = combineReducers(config);
export const useAppDispatch: () => AppDispatch = useDispatch;

export default appReducer;
