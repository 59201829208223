export const URLS = {
  signin: `/signin`,
  forgot: `/forgot`,
  resetPassword: `/reset-password/:token`,
  home: `/`,
  data: `/data`,
  management: `/management`,
  parts: `/parts`,
  import: `/import`,
  prepare: `/prepare`,
  api: `/api`,
  admin: `/admin`,
  adminCompany: `/admin/:companyId`,
} as const;
