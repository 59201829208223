import React from "react";
import { Card } from "@sahadevia/ui-kit-modern-sahadevia";
import "./VirtuousTable.css"; // Make sure to include this CSS file for styling

const data = [
  { name: "Citroën", emissions: "57.26 kg", highlighted: true },
  { name: "Renault", emissions: "58 kg", highlighted: true },
  { name: "Peugeot", emissions: "60.02 kg", highlighted: true },
  { name: "Audi", emissions: "57.26 kg", highlighted: false },
  { name: "BMW", emissions: "57.26 kg", highlighted: false },
  { name: "Fiat", emissions: "57.26 kg", highlighted: false },
  { name: "Skoda", emissions: "57.26 kg", highlighted: false },
];

const VirtuousTable: React.FC = () => {
  return (
    <Card title="Les constructeurs les + vertueux (en CO2e moyen)" className="h-100">
      <table className="virtuous-table">
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className={row.highlighted ? "highlighted" : ""}>
              <td>{row.name}</td>
              <td>{row.emissions}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};

export default VirtuousTable;
