import { TdataInitialState } from "../types/data/dataType";

export const initialState = {
  dataTable: [],
  countPartsRecycler: 0,
  dataPartsRecycler: [],
  countRecycler: 0,
  dataRecycler: [],
  dashboardDataRecycler: null,
} satisfies TdataInitialState;

export const dataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "GET_TABLE":
      return {
        ...state,
        dataTable: action.success ? action.data : [],
      };
    case "GET_DASHBOARD_RECYCLER":
      return {
        ...state,
        dashboardDataRecycler: action.success ? action.data : null,
      };
    case "COUNT_DATA_PARTS_RECYCLER":
      return {
        ...state,
        countPartsRecycler: action.success ? action.data : 0,
      };
    case "GET_DATA_PARTS_RECYCLER":
      return {
        ...state,
        dataPartsRecycler: action.success ? action.data : [],
      };
    case "COUNT_DATA_RECYCLER":
      return {
        ...state,
        countRecycler: action.success ? action.data : 0,
      };
    case "GET_DATA_RECYCLER":
      return {
        ...state,
        dataRecycler: action.success ? action.data : [],
      };
    case "IMPORT_DATA_RECYCLER":
      let tmpDataRecycler = JSON.parse(JSON.stringify(state.dataRecycler));

      if (action.success) {
        tmpDataRecycler.reverse();
        tmpDataRecycler.push(action.data);
        tmpDataRecycler.reverse();
      }
      return {
        ...state,
        dataRecycler: tmpDataRecycler,
      };
    default:
      return state;
  }
};
