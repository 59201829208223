import React from "react";
import { Col, Row } from "react-grid-system";
import { RedocStandalone } from "redoc";

function API() {
  return (
    <Row
      style={{
        rowGap: 30,
        padding: 15,
      }}
    >
      <Col xs={12}>
        <h2 className="mt-0 mb-0">Documentation API</h2>
      </Col>
      <Col xs={12}>
        <RedocStandalone specUrl="test.yaml" />
      </Col>
    </Row>
  );
}

export default API;
