import { Card } from "@sahadevia/ui-kit-modern-sahadevia";
import { getNumber } from "../../utils/general";

interface GeneralCardProps {
  title: string;
  value: number;
  label: string;
  subValue?: number;
  subLabel?: string;
  isFirst?: boolean;
}

function GeneralCard({
  title,
  value,
  label,
  subValue,
  subLabel,
  isFirst,
}: GeneralCardProps) {
  return (
    <Card
      title={title}
      className="h-100"
      style={{
        color: !isFirst ? "" : "var(--primary-bg-color",
        background: !isFirst ? "" : "var(--secondary-bg-color)",
        border: "none",
        height: "100%"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <h1 style={{ margin: 0, fontSize: 50 }}>
          {getNumber(value)} <span style={{ fontSize: 30 }}>{label}</span>
        </h1>
        {subValue ? (
          <p style={{ margin: 0 }}>
            {getNumber(subValue)} {subLabel}
          </p>
        ) : null}
      </div>
    </Card>
  );
}

export default GeneralCard;
