import { useState } from "react";
import {
  Button,
  Flex,
  InputText,
} from "@sahadevia/ui-kit-modern-sahadevia";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import styles from "../../styles/auth/Auth.module.css";
import globalStyles from "../../styles/global.module.css";

import { useAppDispatch } from "../../reducers";

import { resetPassword } from "../../actions/authAction";

type Props = {
  isMobile?: boolean;
};

const ForgotPassword = ({ isMobile }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");
  const [errorInput, setErrorInput] = useState(false);
  const [mailIsSend, setMailIsSend] = useState(false);

  const onResetPassword = (email: string) => {
    dispatch(
      resetPassword(email, (result) => {
        if (result.success) {
          setMailIsSend(true);
        }
      })
    );
  };

  return (
    <div
      className={classNames(styles.signin_main_container, {
        [styles.signin_main_container_mobile]: isMobile,
      })}
    >
      <div
        className={classNames(styles.auth_forgot_password_main_container, {
          [styles.auth_forgot_password_main_container_mobile]: isMobile,
        })}
      >
        {!mailIsSend ? (
          <>
            <h1
              className={classNames(globalStyles.global_title, {
                [globalStyles.global_title_mobile]: isMobile,
              })}
            >
              Mot de passe oublié
            </h1>
            <p style={{ textAlign: "center" }}>
              Veuillez saisir l’adresse email affiliée à votre compte afin que
              nous puissions vous envoyer la procédure de réinitialisation de
              votre mot de passe.
            </p>
            <InputText
              onFocus={() => {
                if (errorInput) {
                  setErrorInput(false);
                }
              }}
              error={errorInput}
              type="email"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
              placeholder={"Email"}
              iconLeft={{ name: "Envelope" }}
            />
            {!email && errorInput ? (
              <p style={{ margin: "auto", marginLeft: 0, color: "#B71C1C" }}>
                Veuillez remplir tous champs
              </p>
            ) : null}
            <Flex align="center" justify="center" gap={10}>
              <Button outline text={"Retour"} onClick={() => navigate(-1)} />
              <Button
                text={"Suivant"}
                onClick={() => {
                  if (!email) {
                    setErrorInput(true);
                  } else {
                    onResetPassword(email);
                  }
                }}
              />
            </Flex>
          </>
        ) : (
          <>
            <h2 className={styles.auth_forgot_password_title}>Email envoyé</h2>
            <p style={{ textAlign: "center" }}>
              Un mail à été envoyé à votre adresse Email, veuillez cliquer sur
              le lien qu’il contient pour réinitialiser votre mot de passe. Si
              vous ne recevez pas le mail veuillez cliquer{" "}
              <button
                style={{
                  color: "var(--primary-bg-color)",
                  background: "none",
                  border: "none",
                  padding: "0",
                  cursor: "pointer",
                  fontSize: 16
                }}
                className="cursor-pointer"
                onClick={() => {
                  if (!email) {
                    setErrorInput(true);
                  } else {
                    onResetPassword(email);
                  }
                }}
              >
                ici
              </button>{" "}
              pour renvoyer le mail.
            </p>
            <Flex align="center" justify="center" gap={10}>
              <Button
                outline
                text={" revenir à la page de connexion"}
                onClick={() => navigate(-1)}
              />
            </Flex>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
