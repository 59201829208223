import { Row, Col } from "react-grid-system";

function Data() {
  return (
    <Row
      style={{
        rowGap: 30,
        padding: 15,
      }}
    >
      <Col xs={12}>
        <h2 className="mt-0 mb-0">Données</h2>
      </Col>
    </Row>
  );
}

export default Data;
