import React, { useState } from "react";
import { useSelector } from "react-redux";

import "./DashboardTable.css"; // Include your CSS file for styling

import { TbaseReducer } from "../../types/reducersType";

const data = Array(10).fill({
  expert: "Expert 1",
  emission: "33.21",
  txPRE: "10.78%",
  txRep: "73.33%",
  nbMO: "12,05",
  cmRep: "1647,73 €",
  txEAD: "40.47%",
  nbTrajetExpert: "1,37",
  nbTrajetClient: "7.07",
  nbPiecesNeuves: "2.79",
});

const DashboardTable: React.FC = () => {
  const { dataTable } = useSelector((state: TbaseReducer) => state.dataReducer);

  const [page, setPage] = useState(0);
  const itemsPerPage = 12;

  const handlePageClick = (selectedPage: number) => {
    setPage(selectedPage);
  };

  const paginatedData = dataTable.slice(
    page * itemsPerPage,
    (page + 1) * itemsPerPage
  );

  return (
    <>
      <p>750 entrées</p>

      {/* Tabs */}
      <div className="tabs">
        <button className="tab active">Experts</button>
        <button className="tab">Réparateurs</button>
      </div>

      {/* Table */}
      <table className="dashboard-table">
        <thead>
          <tr>
            <th>Expert</th>
            <th>Émission CO2e moyen (kg)</th>
            <th>Émission CO2e moyen (kg) Échange pièces neuves - Extraction matières</th>
            <th>Émission CO2e moyen (kg) Échange pièces neuves - Fabrication et transport</th>
            <th>Émission CO2e moyen (kg) Réparation</th>
            <th>Émission CO2e moyen (kg) Ingrédient peinture</th>
            <th>Émission CO2e moyen (kg) Trajet assuré</th>
            <th>Émission CO2e moyen (kg) Trajet expert</th>
            <th>Émission CO2e moyen (kg) Locaux + informatique</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((row, index) => (
            <tr key={index}>
              <td className="expert-name">{row.name}</td>
              <td>{row.average}</td>
              <td>{row.average_totalEmission}</td>
              <td>{row.average_totalEmissionConst}</td>
              <td>{row.average_repairPart}</td>
              <td>{row.average_paintPart}</td>
              <td>{row.average_travelingClient}</td>
              <td>{row.average_travelingExpert}</td>
              <td>{row.average_local}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button onClick={() => handlePageClick(page - 1)} disabled={page === 0}>
          &lt;
        </button>

        {
          // @ts-ignore
          [...Array(Math.ceil(data.length / itemsPerPage)).keys()].map(
            (number) => (
              <button
                key={number}
                className={page === number ? "active" : ""}
                onClick={() => handlePageClick(number)}
              >
                {number + 1}
              </button>
            )
          )
        }
        <button
          onClick={() => handlePageClick(page + 1)}
          disabled={page === Math.ceil(data.length / itemsPerPage) - 1}
        >
          &gt;
        </button>
      </div>
    </>
  );
};

export default DashboardTable;
