import { Card } from "@sahadevia/ui-kit-modern-sahadevia";
import React from "react";
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from "recharts";
import { TChart } from "../../types/data/dataType";

// Optional: Colors for each slice
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

interface MyPieChartProps {
  title: string;
  data: TChart[];
}

const MyPieChart = ({ title, data }: MyPieChartProps) => (
  <Card
    title={title}
    className="h-100"
    style={{
      backgroundColor: "var(--white)",
      border: "none",
    }}
  >
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%" // Position in the middle
          cy="50%"
          outerRadius={100}
          fill="#8884d8"
          label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  </Card>
);

export default MyPieChart;
